import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';

import { resolveSlice } from 'utils/slice-resolver.js';

import css from './customer-area.module.scss';
import shared from 'styles/shared.module.scss';

const CustomerArea = data => {
  const { prismic } = useStaticQuery(graphql`
    query CustomerArea {
      prismic {
        allCustomer_areas {
          edges {
            node {
              _meta {
                uid
              }
              title
              intro
              body {
                ... on PRISMIC_Customer_areaBodyVideo_section {
                  type
                  label
                  primary {
                    video_description
                    video_label
                    video_link {
                      ... on PRISMIC__ExternalLink {
                        url
                      }
                    }
                    video_title
                  }
                }
                ... on PRISMIC_Customer_areaBodyContent_section {
                  type
                  label
                  fields {
                    main
                    content_text
                    image
                    imageSharp {
                      childImageSharp {
                        fluid(quality: 90, maxWidth: 800) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    link_label
                    link {
                      ... on PRISMIC__FileLink {
                        url
                      }
                      ... on PRISMIC__ExternalLink {
                        url
                      }
                    }
                  }
                  primary {
                    type
                    title
                    alignment
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const content = prismic.allCustomer_areas.edges[0];
  if (!content) return null;

  const customerArea = content.node;
  const slices = customerArea.body || [];

  return (
    <>
      <section className={classnames(css.host, shared.maxwidth)}>
        <h4 className={shared.sectionType}>{customerArea.intro}</h4>
        <h2 className={shared.sectionTitle}>{customerArea.title}</h2>
      </section>
      {slices.map(resolveSlice)}
    </>
  );
};

export default CustomerArea;
