import React from 'react';
import { Router } from '@reach/router';

import PrivateRoute from 'components/PrivateRoute';
import CustomerArea from 'components/CustomerArea';
import CustomerLogin from 'components/CustomerLogin';

const App = () => (
  <Router>
    <PrivateRoute path="/customer/area" component={CustomerArea} />
    <CustomerLogin path="/customer/login" />
  </Router>
);

export default App;
