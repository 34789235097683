import JWT from 'jwt-decode';

const TOKEN = 'swissdecode-area-token';

export const isBrowser = () => typeof window !== 'undefined';

export const getToken = () =>
  isBrowser() && window.localStorage.getItem(TOKEN)
    ? window.localStorage.getItem(TOKEN)
    : {};

export const setToken = token =>
  isBrowser() && window.localStorage.setItem(TOKEN, token);

export const isLoggedIn = () => {
  const token = getToken();
  try {
    const decoded = JWT(token);
    return !!decoded.user;
  } catch {
    return false;
  }
};

export const logout = () => {
  isBrowser() && window.localStorage.clearItem(TOKEN);
};
