import React, { useCallback, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import classnames from 'classnames';

import Button from 'components/Button';
import { setToken } from 'utils/auth';

import css from './customer-login.module.scss';
import shared from 'styles/shared.module.scss';

const CustomerLogin = () => {
  const { prismic } = useStaticQuery(graphql`
    query CustomerLogin {
      prismic {
        allCustomer_areas {
          edges {
            node {
              _meta {
                uid
              }
              title
              intro
            }
          }
        }
      }
    }
  `);
  const content = prismic.allCustomer_areas.edges[0];
  const [error, setError] = useState(false);
  const [code, setCode] = useState(null);

  const handleSubmit = useCallback(
    evt => {
      evt.preventDefault();
      setError(false);
      fetch('/.netlify/functions/request-area', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
        }),
      })
        .then(async res => {
          if (res.status === 200) {
            const token = await res.text();
            setToken(token);
            navigate(`/customer/area`, { replace: true });
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    },
    [code]
  );

  if (!content) return null;
  const customerArea = content.node;

  return (
    <>
      <section className={classnames(css.host, shared.maxwidth)}>
        <h4 className={shared.sectionType}>{customerArea.intro}</h4>
        <h2 className={shared.sectionTitle}>{customerArea.title}</h2>
        <form className={css.login} onSubmit={handleSubmit}>
          <label className={css.loginLabel} htmlFor="code">
            Enter code
          </label>
          <input
            id="code"
            type="password"
            aria-label="Enter code"
            onChange={evt => {
              setCode(evt.target.value);
            }}
            className={css.loginInput}
          />
          <Button type="submit" filled>
            Submit
          </Button>
          {error && <div className={css.error}>Invalid code</div>}
        </form>
      </section>
    </>
  );
};

export default CustomerLogin;
